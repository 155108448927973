import type { AxiosError } from 'axios';

import { TZDate } from '@date-fns/tz';
import axios from 'axios';
import { isAfter } from 'date-fns';
import { defineStore } from 'pinia';
import { computed, ref, type Ref, watch } from 'vue';
import { useToast } from 'vue-toastification';

import type { ReunionMode } from '@/config';

import config from '@/config';
import { getSessionStorage, persistToSessionStorage } from '@/lib/local-storage';
import { reunionService } from '@/lib/services';
import { type OSetup, PaymentTypes } from '@/lib/VisBook';

import { BookingStatus } from './graduation';
import { useUserStore } from './user';

const toast = useToast();

export interface CartItem {
  priceId: number;
  webProdcutId: number;
  name: string;
  allergies: string;
  gradYear: string;
  basePrice: number;
}

interface RealCartItem {
  priceId: number;
  webProdcutId: number;
  name: string;
  basePrice: number;
  allergies: string;
  gradYear: string;
  guestNames: string | undefined;
}

export const useReunionStore = defineStore('reunion', () => {
  const visbookSetup: Ref<OSetup | undefined> = ref();
  // @ts-expect-error this is okay
  const availTickets: Ref<CartItem[]> = ref(JSON.parse(sessionStorage.getItem('reunionAvailTickets')) || []);
  // @ts-expect-error this is okay
  const cart: Ref<RealCartItem[]> = ref(JSON.parse(sessionStorage.getItem('reunionCart')) || []);

  const paymentLoading: Ref<boolean> = ref(true);

  const totalGuests = computed(() => cart.value.length);
  const totalPrice = computed(() => cart.value.reduce((a, b) => a + b.basePrice, 0));

  const ticketMode: Ref<ReunionMode> = ref(config.mode);

  watch(cart, async () => {
    persistToSessionStorage('reunionCart', cart);
  }, { deep: true });

  async function getReunionSetup() {
    if (!getSessionStorage('reunionSetup')) {
      visbookSetup.value = await reunionService.getSetup();
      persistToSessionStorage('reunionSetup', visbookSetup);
    }
    else {
      visbookSetup.value = getSessionStorage('reunionSetup');
    }
  }

  const bookingOpen: Ref<BookingStatus> = ref(BookingStatus.PRE);
  const openingDate: Ref<Date | undefined> = ref();
  const closingDate: Ref<Date | undefined> = ref();
  const loadingOpen: Ref<boolean> = ref(false);

  async function getBookingStatus() {
    await getReunionSetup();
    loadingOpen.value = true;
    const { data } = await axios.get('/api/currentDateTime');
    const serverDate = new TZDate(data.date, 'Europe/Oslo');
    openingDate.value = new TZDate(visbookSetup.value?.properties.open_datetime.text, 'Europe/Oslo');
    closingDate.value = new TZDate(visbookSetup.value?.properties.close_datetime.text, 'Europe/Oslo');

    if (isAfter(serverDate, openingDate.value)) {
      bookingOpen.value = BookingStatus.OPEN;
    }

    if (isAfter(serverDate, closingDate.value)) {
      bookingOpen.value = BookingStatus.CLOSED;
    }

    loadingOpen.value = false;
  }

  async function getReunionTicketTypes() {
    if (!availTickets.value.length) {
      const ticketTypes = await reunionService.getWebProductsListById({
        from: '2025-05-27',
        to: '2025-08-27',
        id: 167450,
      });

      ticketTypes.prices.forEach((type) => {
        availTickets.value.push({
          webProdcutId: ticketTypes.id,
          priceId: type.id,
          name: type.name,
          allergies: '',
          gradYear: '',
          basePrice: type.originalPricePerStep,
        });

        persistToSessionStorage('reunionAvailTickets', availTickets);
      });
    }
  }

  async function payNow(terms: boolean) {
    if (!terms) {
      toast.error('You must accept the terms.');
      throw new Error('You must accept the terms');
    }
    const userStore = useUserStore();
    paymentLoading.value = true;
    const reservations: any = [];

    try {
      for await (const item of cart.value) {
        const reservation = await reunionService.createReservation({
          fromDate: '2025-08-06T07:00:00+02:00',
          toDate: '2025-08-06T08:00:00+02:00',
          numberOfPeople: 1,
          notes: `Graduation year: ${item.gradYear}\n\nAllergies: ${item.allergies}`,
          priceId: item.priceId,
          webProductId: item.webProdcutId,
          guestsNames: item.guestNames,
        });
        reservations.push(reservation[0]);
      };

      const checkout = await reunionService.checkoutAndCreate({
        acceptedTerms: terms,
        amount: totalPrice.value,
        customer: userStore.visBookUser,
        paymentType: PaymentTypes.partialPayment,
        reservations,
        successUrl: 'https://bookings.uwcconnect.com/confirmation?from=reunion',
        errorUrl: 'https://bookings.uwcconnect.com/reunion/checkout',
      });

      if (checkout.checkoutStatus === 'ok') {
        window.location.href = checkout.terminalUrl;
      }
    }
    catch (e) {
      const error = e as AxiosError;
      // @ts-expect-error okay
      toast.error(error.response?.data.error || error.message);
      paymentLoading.value = false;
    }
  }

  function reset() {
    availTickets.value = [];
    cart.value = [];
    // @ts-expect-error okay
    arrivalDate.value = null;
    sessionStorage.clear();
  }

  return { visbookSetup, ticketMode, reset, getReunionSetup, closingDate, loadingOpen, paymentLoading, getReunionTicketTypes, payNow, getBookingStatus, totalGuests, totalPrice, cart, availTickets, bookingOpen, openingDate };
});
