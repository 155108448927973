<script setup lang="ts">
import axios from 'axios';
import { differenceInDays } from 'date-fns';
import { onMounted } from 'vue';
import { RouterView } from 'vue-router';
import { POSITION, TYPE, useToast } from 'vue-toastification';

import ToastStatus from './components/toast-status.vue';
import { Impact, type Incident } from './lib/types';

const toast = useToast();

onMounted(async () => {
  const activeIncidents = await axios.get('https://w841yjxynxqn.statuspage.io/api/v2/incidents/unresolved.json');
  const upcoming = await axios.get('https://w841yjxynxqn.statuspage.io/api/v2/scheduled-maintenances/upcoming.json');
  const active = await axios.get('https://w841yjxynxqn.statuspage.io/api/v2/scheduled-maintenances/active.json');

  if (upcoming.data.scheduled_maintenances.length && !active.data.incidents && !activeIncidents.data.scheduled_maintenances) {
    if (differenceInDays(upcoming.data.scheduled_maintenances[0].scheduled_for, new Date()) < 2) {
      toast({
        component: ToastStatus,
        props: {
          incident: upcoming.data.scheduled_maintenances[0],
        },
      }, {
        timeout: false,
        position: POSITION.BOTTOM_LEFT,
        type: TYPE.INFO,
      });
    }
  }

  if (active.data.scheduled_maintenances.length) {
    toast({
      component: ToastStatus,
      props: {
        incident: active.data.scheduled_maintenances[0],
      },
    }, {
      timeout: false,
      position: POSITION.BOTTOM_LEFT,
      type: TYPE.INFO,
    });
  }

  if (activeIncidents.data.incidents.length) {
    activeIncidents.data.incidents.forEach((incident: Incident) => {
      let toastType;
      if (incident.impact === Impact.NONE)
        toastType = TYPE.WARNING;
      if (incident.impact === Impact.MINOR)
        toastType = TYPE.WARNING;
      if (incident.impact === Impact.MAJOR)
        toastType = TYPE.ERROR;
      if (incident.impact === Impact.CRITICAL)
        toastType = TYPE.ERROR;
      toast({
        component: ToastStatus,
        props: {
          incident,
        },
      }, {
        timeout: false,
        position: POSITION.BOTTOM_LEFT,
        type: toastType,
      });
    });
  }
});
</script>

<template>
  <div class="wrapper">
    <RouterView />
    <footer>
      <div class="footer__upper">
        <picture>
          <img
            src="@/assets/connect_white.png"
            alt="UWC Connect Logo"
            width="240.48px"
            height="35px"
          >
        </picture>
        <p>Hauglandsvegen 304</p>
        <p>6968 Flekke</p>
        <p class="email">
          <a href="mailto:connect@uwcrcn.no">connect@uwcrcn.no</a>
        </p>
        <p class="org-no">
          NO 913 125 746 MVA
        </p>
      </div>
      <div class="footer__lower">
        <a href="https://uwcconnect.com/personvern-og-cookies/">Personvern og cookies</a>
      </div>
    </footer>
  </div>
</template>

<style>
main {
  padding: 10px 5px;
  min-height: 100%;
  margin-top: 20px;
}

.wrapper {
  display: flex;
  min-height: 100dvh;
  flex-direction: column;
  justify-content: space-between;
}

footer {
  margin-top: auto;
  color: white;
  background-color: #06897e;

  text-align: center;
  img {
    height: 35px;
    margin-bottom: 10px;
  }
  p {
    margin: 0;
  }

  .org-no {
    font-size: 1rem;
    margin-top: 5px;
  }
  .footer__lower {
    background: #045851;
    padding: 10px 0;
    font-size: 1rem;
  }

  a {
    color: white;
    text-decoration: none;
  }
  .footer__upper {
    padding: 20px 20%;
  }
  .email {
    margin: 5px 0;
  }
}

table {
  width: 100% !important;
}

button.button-primary {
  cursor: pointer;
}

button.button-primary:disabled {
  color: #858585;
  background-color: #ebebeb;
  border-color: #ebebeb;
  cursor: not-allowed;

  &:hover {
    color: #858585;
    background-color: #ebebeb;
    border-color: #ebebeb;
  }
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #06897e;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.smaller {
  width: 20px;
  height: 20px;
  border-width: 4px;
}

input:disabled {
  cursor: not-allowed !important;
}

.Vue-Toastification__toast--warning {
  background-color: #e67e22 !important;
  color: #fff;
}

main {
  margin-bottom: 25px;
}
</style>
