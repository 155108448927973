<script setup lang="ts">
import { useHead } from '@unhead/vue';
import { marked } from 'marked';
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

import { usePeacebuildingStore } from '@/stores/peacebuilding';

const peacebuildingStore = usePeacebuildingStore();
const router = useRouter();
const toast = useToast();

useHead({
  title: 'Peacebuilding Workshop',
  meta: [
    {
      name: 'description',
      content: 'The bookings website for the peacebuilding workshop at UWCRCN.',
    },
  ],
});

onMounted(async () => {
  await peacebuildingStore.getSetup();
});

async function updateArrivalDate() {
  await peacebuildingStore.getTicketTypes();
  addGuest();
}

async function addGuest(overide = false) {
  const priceId = 107;
  if (overide || !peacebuildingStore.cart.length) {
    const foundTicket = peacebuildingStore.availTickets.find(x => x.priceId === +priceId);
    if (foundTicket) {
      peacebuildingStore.cart.push({
        webProdcutId: foundTicket.webProdcutId,
        priceId: foundTicket.priceId,
        name: foundTicket.name,
        notes: '',
        basePrice: foundTicket.basePrice,
        undervisingId: foundTicket.undervisingId,
        undervisingPrice: foundTicket.undervisingPrice,
        companyId: foundTicket.companyId,
        guestNames: '',
      });
    }
  }
}

function removeGuest(i: number) {
  peacebuildingStore.cart.splice(i, 1);
}

function validateAndContinue() {
  try {
    for (const item of peacebuildingStore.cart) {
      if (!item.guestNames?.length)
        throw new Error('You must enter a name for all guests.');
    };
    router.push({ name: 'login', query: { redirectTo: '/peacebuilding/customer' } });
  }
  catch (e) {
    const error = e as Error;
    toast.error(error.message);
  }
}
</script>

<template>
  <teleport to="head">
    <component is="script" type="application/ld+json">
      {
      "@context": "https://schema.org",
      "@type": "Event",
      "location": {
      "@type": "Place",
      "address": {
      "@type": "PostalAddress",
      "addressLocality": "Flekke",
      "postalCode": "6968",
      "streetAddress": "Haugland"
      },
      "name": "UWC Red Cross Nordic"
      },
      "name": "{{ peacebuildingStore.visbookSetup?.name }}",
      "offers": {
      "@type": "Offer",
      "price": "{{ peacebuildingStore.availTickets[0]?.basePrice || 0 }}",
      "priceCurrency": "NOK",
      "url": "http://bookings.uwcconnect.com/peacebuilding"
      },
      "startDate": "{{ peacebuildingStore.visbookSetup?.properties.event_start?.text || null }}",
      "endDate": "{{ peacebuildingStore.visbookSetup?.properties.event_start?.text || null }}"
      }
    </component>
  </teleport>
  <header class="peacebuilding">
    <div class=" header-wrapper">
      <div class="container">
        <!-- columns should be the immediate child of a .row -->
        <div class="logos">
          <img src="@/assets/connect_white.png" alt="" width="240.48px" height="35px">
          <img src="@/assets/rcn_white.png" alt="" width="240.48px" height="35px" class="rcn-logo">
        </div>
        <div class="text">
          <h1>{{ peacebuildingStore.visbookSetup?.name }}</h1>
          <p class="dates">
            19 - 26 January
          </p>
        </div>
      </div>
    </div>
  </header>
  <main>
    <div class="container">
      <div class="row">
        <div v-if="peacebuildingStore.bookingOpen" class="eight columns">
          <div class="content-card inline">
            <div>
              <label for="selectArrival">Select Arrival Date</label>
              <!-- @vue-expect-error -->
              <select id="selectArrival" v-model="peacebuildingStore.arrivalDate" class="u-full-width" :disabled="peacebuildingStore.arrivalDate" @change="updateArrivalDate">
                <option />
                <option value="2025-01-18">
                  18-01-2025
                </option>
                <option value="2025-01-19">
                  19-01-2025
                </option>
                <option value="2025-01-20">
                  20-01-2025
                </option>
              </select>
            </div>
            <button @click="peacebuildingStore.reset">
              Reset
            </button>
          </div>

          <div v-if="peacebuildingStore.totalGuests > 0" class="content-card">
            <p style="margin-bottom: 0;">
              Total Price: {{ peacebuildingStore.totalPrice }}
            </p>
          </div>

          <div
            v-for="(ticket, i) in peacebuildingStore.cart" :key="ticket.priceId" class="content-card"
          >
            <div class="actions">
              <h2>Ticket {{ i + 1 }}</h2>
              <button v-if="i > 0" @click="removeGuest(i)">
                ❌
              </button>
            </div>
            <div class="row">
              <div class="columns">
                <label for="exampleEmailInput">Full name*</label>
                <input id="exampleEmailInpu" v-model="ticket.guestNames" class="u-full-width" type="text" placeholder="John Smith">
              </div>
            </div>
            <div class="row">
              <div class="columns">
                <label for="alleriges">Allergies/Dietry Requirements</label>
                <input id="alleriges" v-model="ticket.notes" class="u-full-width" type="text">
              </div>
            </div>
          </div>

          <button v-if="peacebuildingStore.totalGuests > 0" class="button-primary" @click="addGuest(true)">
            Add a Guest
          </button>

          <div v-if="peacebuildingStore.totalGuests > 0" class="card buttons" style="justify-content: end">
            <button class="button-primary" @click="validateAndContinue">
              Continue
            </button>
          </div>
        </div>
        <div v-if="!peacebuildingStore.bookingOpen" class="eight columns">
          <div class="content-card inline">
            Bookings are currently closed.
          </div>
        </div>
        <div class="four columns right">
          <div v-if="peacebuildingStore.visbookSetup?.properties.price" class="content-card">
            <div class="card-title">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-credit-card"
              >
                <rect x="1" y="4" width="22" height="16" rx="2" ry="2" />
                <line x1="1" y1="10" x2="23" y2="10" />
              </svg>
              Prices
            </div>
            <div
              v-if="peacebuildingStore.visbookSetup.properties.price"
              class="content"
              v-html="
                marked(peacebuildingStore.visbookSetup.properties.price.text)
              "
            />
            <div class="cards">
              <img
                class="visa"
                src="@/assets/cards/visa.svg"
                alt="VISA Logo"
                height="19.47px"
                width="60px"
              >
              <img
                class="ma"
                src="@/assets/cards/mc.svg"
                height="34.94px"
                width="45px"
                alt="MasterCard Logo"
              >
              <img
                class="ma"
                src="@/assets/cards/ms.svg"
                height="35px"
                width="45px"
                alt="Maestro Logo"
              >
            </div>
          </div>

          <div v-if="peacebuildingStore.visbookSetup?.properties.contact" class="content-card">
            <div class="card-title">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-mail"
              >
                <path
                  d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
                />
                <polyline points="22,6 12,13 2,6" />
              </svg>
              Contact
            </div>
            <div
              class="content"
              v-html="
                marked(peacebuildingStore.visbookSetup.properties.contact.text || '')
              "
            />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<style scoped>
header.peacebuilding {
  background-color: rgb(0, 100, 166);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-bottom: 10px solid #009b90;
  color: white;
  min-height: 200px;

  .header-wrapper {
    background-color: rgba(0, 0, 0, 0.77);
    min-height: 200px;
  }

  .logos {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
  }
}

.inline {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  div {
    width: 100%;
  }
  button {
    height: 100%;
  }
}

.eight {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.text {
  text-align: center;

  h1 {
    font-size: 40px;
    margin: 20px 0 0;
  }

  .year {
    font-weight: bold;
    font-size: 25px;
    color: #009b90;
    margin: 0 0 20px;
  }

  .dates {
    font-style: italic;
    font-size: 20px;
    margin: 0 0 20px;
  }
}

.content-card {
  background-color: white;
  box-shadow: 10px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
}

.card-title {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 20px;
  font-weight: bold;
  color: #009b90;
}

.content {
  p,
  h1,
  h2,
  h3,
  h4 {
    margin: 10px 0 0;
    padding: 0;
  }
}

.right {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cards {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center {
  width: 100%;
  margin: 10px 0;
}

button {
  display: inline-block;
}

.card {
  background-color: white;
  box-shadow: 10px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
}

.card p,
.card h1,
.card button {
  margin: 0;
}

.countdown-wrapper {
  margin-bottom: 20px;
}

.countdown {
  text-align: center;
  h2 {
    display: block;
    font-size: 2rem;
    margin-bottom: 0;
  }

  .timer {
    margin: 5px 0;
    font-size: 3rem;
    font-weight: bold;
  }
}

.row {
  gap: 20px;
}

@media (max-width: 550px) {
  .eight {
    margin-bottom: 20px;
  }
}

@media (max-width: 620px) {
  .rcn-logo {
    display: none;
  }
  header .logos {
    justify-content: center;
  }
}

.buttons,
.actions {
  display: flex;
  justify-content: space-between;
}
</style>
