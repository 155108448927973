export interface Incident {
  created_at: Date;
  id: string;
  impact: Impact;
  incident_updates: IncidentUpdate[];
  monitoring_at: null;
  name: string;
  page_id: string;
  resolved_at: null;
  shortlink: string;
  status: Status;
  updated_at: Date;
  components: Component[];
}

export interface ScheduledMaintenances {
  created_at: Date;
  id: string;
  impact: Impact;
  incident_updates: IncidentUpdate[];
  monitoring_at: null;
  name: string;
  page_id: string;
  resolved_at: null;
  scheduled_for: Date;
  scheduled_until: Date;
  shortlink: string;
  status: Status;
  updated_at: Date;
  components: Component[];
}

export interface IncidentUpdate {
  body: string;
  created_at: Date;
  display_at: Date;
  id: string;
  incident_id: string;
  status: Status;
  updated_at: Date;
}

export enum Impact {
  NONE = 'none',
  MINOR = 'minor',
  MAJOR = 'major',
  CRITICAL = 'critical',
}

export interface Component {
  id: string;
  name: string;
  status: string;
  created_at: Date;
  updated_at: Date;
  position: number;
  description: null;
  showcase: boolean;
  start_date: Date;
  group_id: string;
  page_id: string;
  group: boolean;
  only_show_if_degraded: boolean;
}

export enum Status {
  scheduled = 'Scheduled',
  in_progress = 'In Progress',
  identified = 'Identified',
  investigating = 'Investigating',
  verifying = 'Verifying',
  monitoring = 'Monitoring',
}
