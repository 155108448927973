<script setup lang="ts">
import { TZDate } from '@date-fns/tz';
import { useSeoMeta } from '@unhead/vue';
import { marked } from 'marked';
import { onMounted, ref, type Ref } from 'vue';
import { useRouter } from 'vue-router';

import config from '@/config';
import { BookingStatus } from '@/stores/graduation';
import { useReunionStore } from '@/stores/reunion';

const reunionStore = useReunionStore();
const router = useRouter();
const countdown: Ref<string> = ref('');

useSeoMeta({
  title: 'RCN Reunion - August',
  description: 'The bookings website for the UWCRCN Reunion in August.',
  ogDescription: 'The bookings website for the UWCRCN Reunion in August.',
  ogTitle: 'RCN Reunion - August',
  ogImage: `${config.baseUrl}/images/reunion.jpg`,
  twitterCard: 'summary_large_image',
  ogType: 'website',
});

onMounted(async () => {
  await reunionStore.getReunionSetup();
  await reunionStore.getReunionTicketTypes();
  await reunionStore.getBookingStatus();
  const openingDate = reunionStore.openingDate?.getTime();

  function getCountdown() {
    const now = new TZDate(new Date(), 'Europe/Oslo').getTime();

    // Find the distance between now and the count down date
    const distance = openingDate! - now;

    // Time calculations for days, hours, minutes and seconds
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Display the result in the element with id="demo"
    countdown.value = `${(days) ? `${days}d` : ''} ${(hours) ? `${hours}h` : ''} ${(minutes) ? `${minutes}m` : ''} ${seconds}s `;

    return distance;
  }

  if (reunionStore.bookingOpen === BookingStatus.PRE) {
    // Update the count down every 1 second
    getCountdown();
    const x = setInterval(() => {
      // Get today's date and time in Oslo
      const distance = getCountdown();
      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        countdown.value = 'Is your clock set correctly?';
        reunionStore.getBookingStatus();
      }
    }, 1000);
  }
});
</script>

<template>
  <teleport to="head">
    <component is="script" type="application/ld+json">
      {
      "@context": "https://schema.org",
      "@type": "Event",
      "location": {
      "@type": "Place",
      "address": {
      "@type": "PostalAddress",
      "addressLocality": "Flekke",
      "postalCode": "6968",
      "streetAddress": "Haugland"
      },
      "name": "UWC Red Cross Nordic"
      },
      "name": "{{ reunionStore.visbookSetup?.name }}",
      "offers": {
      "@type": "Offer",
      "price": "{{ reunionStore.availTickets[0]?.basePrice || 0 }}",
      "priceCurrency": "NOK",
      "url": "http://bookings.uwcconnect.com/may-reunion"
      },
      "startDate": "{{ reunionStore.visbookSetup?.properties.event_start.text || null }}",
      "endDate": "{{ reunionStore.visbookSetup?.properties.event_start.text || null }}"
      }
    </component>
  </teleport>
  <header class="special">
    <div class=" header-wrapper">
      <div class="container">
        <!-- columns should be the immediate child of a .row -->
        <div class="logos">
          <img src="@/assets/connect_white.png" alt="" width="240.48px" height="35px">
          <img src="@/assets/rcn_white.png" alt="" width="240.48px" height="35px" class="rcn-logo">
        </div>
        <div class="text">
          <h1>{{ reunionStore.visbookSetup?.name }}</h1>
          <p class="year">
            2005/2006
          </p>
          <p class="dates">
            5 - 9 August
          </p>
          <button v-if="reunionStore.bookingOpen === BookingStatus.OPEN" class="button-primary" @click="router.push({ name: 'login', query: { redirectTo: '/reunion/ticket' } })">
            Get Your Ticket
          </button>
        </div>
      </div>
    </div>
  </header>
  <main>
    <div class="container">
      <div v-if="reunionStore.bookingOpen === BookingStatus.PRE && !reunionStore.loadingOpen" class="row countdown-wrapper">
        <div class="card">
          <div class="countdown">
            <h2 class="card-title">
              Bookings open in
            </h2>
            <p class="timer">
              {{ countdown }}
            </p>
            <p v-if="reunionStore.openingDate" class="date">
              {{ new Intl.DateTimeFormat('en-GB', {
                dateStyle: 'full',
                timeStyle: 'long',
              }).format(new Date(reunionStore.openingDate)) }}
            </p>
          </div>
        </div>
      </div>

      <div v-if="reunionStore.bookingOpen === BookingStatus.CLOSED && !reunionStore.loadingOpen" class="row countdown-wrapper">
        <div class="card">
          <div class="countdown">
            <h2 class="card-title">
              Bookings now closed.
            </h2>
            <p v-if="reunionStore.openingDate" class="date">
              {{ new Intl.DateTimeFormat('en-GB', {
                dateStyle: 'full',
                timeStyle: 'long',
              }).format(new Date(reunionStore.closingDate!)) }}
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="eight columns">
          <div v-if="reunionStore.visbookSetup?.properties.description_long.text" class="content-card">
            <div class="card-title">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-info"
              >
                <circle cx="12" cy="12" r="10" />
                <line x1="12" y1="16" x2="12" y2="12" />
                <line x1="12" y1="8" x2="12.01" y2="8" />
              </svg>
              Information
            </div>
            <div
              class="content"
              v-html="
                marked(reunionStore.visbookSetup.properties.description_long.text)
              "
            />
          </div>
        </div>
        <div class="four columns right">
          <div v-if="reunionStore.visbookSetup?.properties.description_long.text" class="content-card">
            <div class="card-title">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-credit-card"
              >
                <rect x="1" y="4" width="22" height="16" rx="2" ry="2" />
                <line x1="1" y1="10" x2="23" y2="10" />
              </svg>
              Prices
            </div>
            <div
              v-if="reunionStore.visbookSetup.properties.price"
              class="content"
              v-html="
                marked(reunionStore.visbookSetup.properties.price.text)
              "
            />
            <button v-if="reunionStore.bookingOpen === BookingStatus.OPEN" class="button-primary center" @click="router.push({ name: 'login', query: { redirectTo: '/reunion/ticket' } })">
              Get Your Ticket
            </button>
            <div class="cards">
              <img
                class="visa"
                src="@/assets/cards/visa.svg"
                alt="VISA Logo"
                height="19.47px"
                width="60px"
              >
              <img
                class="ma"
                src="@/assets/cards/mc.svg"
                height="34.94px"
                width="45px"
                alt="MasterCard Logo"
              >
              <img
                class="ma"
                src="@/assets/cards/ms.svg"
                height="35px"
                width="45px"
                alt="Maestro Logo"
              >
            </div>
          </div>

          <div v-if="reunionStore.visbookSetup?.properties.description_long.text" class="content-card">
            <div class="card-title">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-mail"
              >
                <path
                  d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
                />
                <polyline points="22,6 12,13 2,6" />
              </svg>
              Contact
            </div>
            <div
              class="content"
              v-html="
                marked(reunionStore.visbookSetup.properties.contact.text || '')
              "
            />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<style scoped>
header.special {
  background-color: rgb(0, 100, 166);
  background-image: url('@/assets/banners/cross.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-bottom: 10px solid #009b90;
  color: white;
  min-height: 300px;

  .header-wrapper {
    background-color: rgba(0, 0, 0, 0.77);
    min-height: 300px;
  }

  .logos {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
  }
}

.right {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.text {
  text-align: center;

  h1 {
    font-size: 40px;
    margin: 20px 0 0;
  }

  .year {
    font-weight: bold;
    font-size: 25px;
    color: #009b90;
    margin: 0 0 20px;
  }

  .dates {
    font-style: italic;
    font-size: 20px;
    margin: 0 0 20px;
  }
}

.content-card {
  background-color: white;
  box-shadow: 10px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
}

.card-title {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 20px;
  font-weight: bold;
  color: #009b90;
}

.content {
  p,
  h1,
  h2,
  h3,
  h4 {
    margin: 10px 0 0;
    padding: 0;
  }
}

.cards {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center {
  width: 100%;
  margin: 10px 0;
}

button {
  display: inline-block;
}

.card {
  background-color: white;
  box-shadow: 10px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
}

.card p,
.card h1,
.card button {
  margin: 0;
}

.countdown-wrapper {
  margin-bottom: 20px;
}

.countdown {
  text-align: center;
  h2 {
    display: block;
    font-size: 2rem;
    margin-bottom: 0;
  }

  .timer {
    margin: 5px 0;
    font-size: 3rem;
    font-weight: bold;
  }
}

.row {
  gap: 20px;
}

@media (max-width: 550px) {
  .eight {
    margin-bottom: 20px;
  }
}

@media (max-width: 620px) {
  .rcn-logo {
    display: none;
  }
  header .logos {
    justify-content: center;
  }
}
</style>
