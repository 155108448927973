import type { AxiosError } from 'axios';

import * as Sentry from '@sentry/browser';
import { defineStore } from 'pinia';
import { computed, ref, type Ref, watch } from 'vue';
import { useToast } from 'vue-toastification';

import type { OSetup, PaymentTypes } from '@/lib/VisBook';

import { persistToSessionStorage } from '@/lib/local-storage';
import { peacebuildingService } from '@/lib/services';

import { useUserStore } from './user';

const toast = useToast();

interface CartItem {
  priceId: number;
  webProdcutId: number;
  name: string;
  basePrice: number;
  undervisingId: number;
  undervisingPrice: number;
  companyId: string;
}

interface RealCartItem {
  priceId: number;
  webProdcutId: number;
  name: string;
  basePrice: number;
  guestNames: string | undefined;
  notes: string | undefined;
  undervisingId: number;
  undervisingPrice: number;
  companyId: string;
}

export const usePeacebuildingStore = defineStore('peacebuilding', () => {
  const visbookSetup: Ref<OSetup | undefined> = ref();
  // @ts-expect-error this is okay
  const availTickets: Ref<CartItem[]> = ref(JSON.parse(sessionStorage.getItem('peaceAvailTickets')) || []);
  // @ts-expect-error this is okay
  const cart: Ref<RealCartItem[]> = ref(JSON.parse(sessionStorage.getItem('peaceCart')) || []);
  // @ts-expect-error this is okay
  const arrivalDate: Ref<string | undefined> = ref(JSON.parse(sessionStorage.getItem('arrivalDate')) || null);

  const bookingOpen: Ref<boolean> = ref(false);
  const openingDate: Ref<Date | undefined> = ref();
  const paymentLoading: Ref<boolean> = ref(false);

  const totalGuests = computed(() => cart.value.length);
  const totalPrice = computed(() => cart.value.reduce((a, b) => a + b.basePrice, 0));

  watch(cart, async () => {
    persistToSessionStorage('peaceCart', cart);
  }, { deep: true });

  watch(arrivalDate, async () => {
    persistToSessionStorage('arrivalDate', arrivalDate);
  });

  async function getSetup() {
    visbookSetup.value = await peacebuildingService.getSetup();
  }

  async function getBookingStatus() {
    return visbookSetup.value?.properties.open_datetime.value || true;
  }

  async function getTicketTypes() {
    if (!availTickets.value.length) {
      const ticketTypes = await peacebuildingService.getWebProductsListById({
        from: arrivalDate.value!,
        to: '2025-01-26',
        id: 178260,
      });

      ticketTypes.prices.forEach((type) => {
        availTickets.value.push({
          webProdcutId: ticketTypes.id,
          priceId: type.id,
          name: type.name,
          basePrice: type.calculatedPrice + ticketTypes.additionalServices[0].price,
          undervisingId: ticketTypes.additionalServices[0].id,
          undervisingPrice: ticketTypes.additionalServices[0].price,
          companyId: ticketTypes.additionalServices[0].encryptedCompanyId,
        });

        persistToSessionStorage('peaceAvailTickets', availTickets);
      });
    }
  }

  async function payNow(terms: boolean, paymentType: PaymentTypes) {
    if (!terms) {
      toast.error('You must accept the terms.');
      throw new Error('You must accept the terms');
    }
    const userStore = useUserStore();
    paymentLoading.value = true;

    const reservations: any = [];

    try {
      for await (const item of cart.value) {
        const reservation = await peacebuildingService.createReservation({
          fromDate: arrivalDate.value!,
          toDate: '2025-01-26',
          numberOfPeople: 1,
          notes: item.notes,
          priceId: item.priceId,
          webProductId: item.webProdcutId,
          guestsNames: item.guestNames,
          additionalServices: [
            {
              count: 1,
              id: item.undervisingId,
              encryptedCompanyId: item.companyId,
            },
          ],
        });
        reservations.push(reservation[0]);
      };

      const checkout = await peacebuildingService.checkoutAndCreate({
        acceptedTerms: terms,
        amount: totalPrice.value,
        customer: userStore.visBookUser,
        paymentType,
        reservations,
        successUrl: 'https://bookings.uwcconnect.com/confirmation?from=peacebuilding',
        errorUrl: 'https://bookings.uwcconnect.com/peacebuilding/checkout',
      });

      if (checkout.checkoutStatus === 'ok') {
        window.location.href = checkout.terminalUrl;
      }

      if (checkout.checkoutStatus === 'invoicePayment') {
        window.location.href = checkout.terminalUrl;
      }
    }
    catch (e) {
      const error = e as AxiosError;
      // @ts-expect-error okay
      toast.error(error.response?.data.error || error.message);

      for await (const item of cart.value) {
        Sentry.setContext('reservtion', {
          fromDate: arrivalDate.value!,
          toDate: '2025-01-26',
          numberOfPeople: 1,
          notes: 'REMOVED',
          priceId: item.priceId,
          webProductId: item.webProdcutId,
          guestsNames: 'REMOVED',
          additionalServices:
            {
              count: 1,
              id: item.undervisingId,
              encryptedCompanyId: item.companyId,
            },
        });
      };

      Sentry.setContext('checkout', {
        acceptedTerms: terms,
        amount: totalPrice.value,
        paymentType,
        reservations,
      });
      Sentry.captureException(error);

      paymentLoading.value = false;
    }
  }

  function reset() {
    availTickets.value = [];
    cart.value = [];
    // @ts-expect-error this is okay
    arrivalDate.value = null;
    sessionStorage.removeItem('peaceCart');
    sessionStorage.removeItem('peaceAvailTickets');
  }

  return { reset, visbookSetup, getSetup, getTicketTypes, payNow, getBookingStatus, paymentLoading, totalGuests, arrivalDate, totalPrice, cart, availTickets, bookingOpen, openingDate };
});
